'use client';

import useHideIntercomChat from '~/hooks/useHideIntercomChat';

const HideIntercomChat = () => {
  useHideIntercomChat(true);

  return null; // No need to render anything for this component
};

export default HideIntercomChat;
